@import '../../mixin';
@import '../../trumps';
@import '../../settings';

.footer {
  margin-top: 32vw;
  @include tablet {
    margin-top: 27vw;
  }
  @include pc {
    margin-top: 20vw;
  }

  &__container {
    width: 84vw;
    margin: 0 auto;
    @include pc {
      width: 90%;
    }
  }

  &__border {
    border-top: 1px solid $bpc-white;
    border-bottom: 1px solid $bpc-white;
  }

  &__inner {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 84vh;
    letter-spacing: 0.1em;
    @include tablet {
      height: 70vh;
    }
    @include pc {
      margin: 0 auto;
      padding: 70px 0;
      width: 80vw;
      height: fit-content;
      flex-direction: row;
      justify-content: flex-start;
    }
  }

  &__logo-area {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 18vw;
    @include tablet {
      margin-bottom: 8vw;
    }
    @include pc {
      margin-right: 13vw;
      margin-bottom: 0;
    }
  }

  &__logo {
    width: 17vw;
    @include pc {
      width: 5vw;
      max-width: 80px;
    }
    @include tablet {
      width: 12vw;
    }
  }

  &__links {
    z-index: 10;
    display: flex;
    flex-direction: column;
    @include pc {
      margin: 0;
      height: 6vw;
      flex-direction: row;
    }
  }

  &__flex {
    @include pc {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 8vw;
    }
  }

  &__margin {
    margin: 0 auto 8vw;
    text-align: center;
    @include tablet {
      margin: 0 auto 3vw;
    }
    @include pc {
      margin: 0 auto;
    }
  }

  &__sns {
    display: flex;
    justify-content: center;
    margin-top: 6vw;
    @include tablet {
      margin-top: 4vw;
    }
    @include pc {
      display: none;
    }
  }

  &__btn {
    margin: 0 14px;
  }

  &__icon {
    width: 19px;
  }

  &__copy {
    width: 100%;
    margin-top: 1vw;
    margin-bottom: 2.5vw;
    @include pc {
      margin: 10px 0 30px;
    }
  }
}

.footer-black {
  color: $bpc-black;
}

.footer-logo-black {
  path {
    fill: $bpc-black;
  }
}

.footer-border-black {
  border-top: 1px solid $bpc-black;
  border-bottom: 1px solid $bpc-black;
}

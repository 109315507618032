@import '../../mixin';
@import '../../settings';

.concept {
  &__container {
    width: 80%;
  }

  &__flex-wrapper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: relative;
    display: flex;
    z-index: 10;
    opacity: 1;
    flex-direction: column;
    align-items: flex-end;
    @include pc {
      flex-direction: row;
      margin: 0;
    }
  }

  &__flex-adjustment {
    margin-bottom: 4.444vw;
    @include tablet {
      margin-bottom: 5.556vw;
    }
    @include pc {
      margin-bottom: 12vw;
    }
  }

  &__title {
    letter-spacing: 0.08em;
    margin-bottom: 15vw;
    @include tablet {
      margin-bottom: 10vw;
    }
  }

  &__body {
    width: 100%;
    letter-spacing: 0.04em;
    margin-bottom: 20vw;
    @include tablet {
      margin-bottom: 16vw;
    }
    @include pc {
      letter-spacing: $default-letter-spacing;
      width: 27vw;
      margin-left: 4vw;
      margin-bottom: 12vw;
    }
  }

  &__en {
    line-height: 1.6;
  }
}

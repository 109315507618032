@import '../../mixin';
@import '../../settings';

.movie {
  position: relative;
  width: 100vw;
  aspect-ratio: 16/9;
  cursor: none;
  // cursor: -webkit-image-set(url("https://d2kq0urxkarztv.cloudfront.net/62504f1cb152db003a4957dd/upload-c195975f-1187-4d47-9439-5cd091a42b61.png?w=128") 1x, url("https://d2kq0urxkarztv.cloudfront.net/62504f1cb152db003a4957dd/upload-c195977f-1187-4d47-9439-5cd091a42b61.png") 2x), auto;
  @include landscape {
    margin-bottom: 10vw;
  }
  @include portrait {
    margin-bottom: 30vw;
  }

  &__bg {
    z-index: -100;
  }

  &__wrap {
    z-index: -100;
  }

  &__shadow {
    box-shadow: 0px 2vw 1.5vw rgba(0, 0, 0, 0.15);
  }

  &__play {
    width: calc(11px + 2vmin);
    margin-bottom: 1vw;
  }

  &__vid {
    z-index: 10;
  }

  &__vid-play {
    aspect-ratio: 16/9;
    background-color: $bpc-black;
  }
}

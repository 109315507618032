// IMPORT FONTS
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Old+Mincho:wght@400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Infant:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200&display=swap');

$default-font-size: 2vmin;
$default-letter-spacing: 0.08em;
$bpc-black: #2a302e;
$bpc-white: #f6f4f1;

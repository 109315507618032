@import '../../mixin';
@import '../../settings';
@import '../../trumps';

.stories {
  position: relative;
  width: 100vw;
  margin-top: 15vw;
  margin-bottom: 32vw;
  @include tablet {
    margin-bottom: 27vw;
  }
  @include pc {
    margin-bottom: 20vw;
  }

  &__container {
    width: 90%;
    margin: 0 0 0 auto;
    @include pc {
      width: 100%;
      margin: 0;
    }
  }

  &__side {
    width: 100%;
    @include pc {
      width: 8.5vw;
    }
  }

  &__contents {
    width: 100%;
    @include pc {
      width: 83vw;
    }
  }

  &__title {
    margin-bottom: 4vw;
    @include tablet {
      margin-bottom: 2.7vw;
    }
    @include pc {
      margin-bottom: 2vw;
      margin-left: 1.5vw;
      text-align: left;
    }
  }

  &__swiper {
    width: 90vw;
    @include pc {
      width: 100%;
    }
  }

  &__content {
    box-sizing: border-box;
    margin-right: 10vw;
    @include tablet {
      margin-right: 5vw;
    }
    @include pc {
      margin: 0 1.5vw;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.75;
  }

  &__text {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: relative;
  }

  &__cover {
    object-fit: cover;
    object-position: center;
  }

  &__play {
    width: 5vw;
    margin-bottom: 1vw;
    @include tablet {
      width: 3vw;
      margin-bottom: 0.5vw;
    }
    @include pc {
      width: 1.5vw;
      margin-bottom: 0.5vw;
    }
  }

  &__progressbar {
    width: 79vw;
    @include pc {
      width: 60vw;
    }
  }

  &__layer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    z-index: 4;
    opacity: 0.5;
    background-color: #000;
  }

  &__popup {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    z-index: 5;
  }

  &__player {
    position: relative;
    z-index: 10;
    @include landscape {
      height: 80svh;
    }
    @include portrait {
      height: 80svh;
      max-width: 90vw;
    }
  }

  .pagination {
    width: 100%;
    height: 2px;
    position: relative;
    background: gray;

    .swiper-pagination-progressbar-fill {
      background-color: $bpc-white;
      opacity: 1;
    }
  }
}

@import '../../mixin';
@import '../../settings';

.gp {
  &__pc-nav {
    position: absolute;
    left: 0;
    right: 0;
    @include landscape {
      display: flex;
      justify-content: center;
      bottom: 0.833vw;
    }
    @include portrait {
      display: none;
    }

    & > ul {
      @include landscape {
        display: inline-block;
      }
    }

    & > ul > li {
      @include landscape {
        display: inline-block;
        margin: 0 1.042vw;
        padding: 0 0.347vw;
        font-size: 1.389vw;
        color: $bpc-black;
      }
    }
  }

  &__sp-nav {
    position: absolute;
    width: 90vw;
    margin: 0 auto;
    left: 0;
    right: 0;
    @include landscape {
      display: none;
    }
    @include portrait {
      display: flex;
      align-items: center;
      bottom: 1.795vw;
    }

    &--icon {
      circle,
      rect {
        fill: $bpc-black;
      }
    }

    & div > ul {
      display: inline-block;
    }

    & div > ul > li {
      font-size: min(4.872vw, 24px);
      border-bottom: 1px solid $bpc-black;
      padding: 0 1.5vw;
    }
  }

  &__sp {
    position: absolute;
    margin-left: calc(25px + 6.5vw);
    right: 0;
    left: 0;
    bottom: 11vw;
    @include tablet {
      bottom: 7vw;
    }
    @include landscape {
      display: none;
    }

    & ul > li {
      font-size: min(4.872vw, 24px);
      padding: 0 0 3.846vw;
    }
  }

  &__container {
    width: 80vw;
    margin: 0 auto;
    @include portrait {
      width: 90vw;
    }
  }

  &__hero {
    margin: 0 auto;
    height: 100svh;
    display: flex;
    align-items: center;
    color: $bpc-black;
    @include landscape {
      width: 60vw;
      margin-bottom: 6.944vw;
    }
    @include portrait {
      width: 80vw;
      margin-bottom: 20vw;
    }
  }

  &__title {
    color: $bpc-black;
    font-weight: 400;
    @include landscape {
    }
    @include portrait {
      font-size: min(4.872vw, 24px);
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    vertical-align: middle;
    display: inline-block;
  }

  &__box {
    margin-bottom: 8.333vw;
    @include portrait {
      margin-bottom: 30.769vw;
    }

    & div > h3 {
      @include landscape {
        margin-bottom: 0.8vw;
      }
      @include portrait {
        margin-bottom: 2vw;
      }
    }
  }

  &__grid-wrapper {
    display: grid;
    grid-auto-flow: dense;
    @include landscape {
      grid-template-columns: repeat(auto-fit, minmax(22.222vw, 1fr));
      grid-gap: 1.042vw 1.25vw;
      grid-auto-rows: 22.222vw;
    }
    @include portrait {
      grid-auto-rows: 40vw;
      // grid-template-columns: 100%;
      grid-gap: 2.564vw;
      grid-template-columns: repeat(auto-fit, minmax(40vw, 1fr));
    }
  }

  &__grid-stories {
    display: grid;
    grid-auto-flow: dense;
    @include landscape {
      grid-template-columns: repeat(auto-fit, minmax(19vw, 4fr));
      grid-gap: 0 1.25vw;
    }
    @include portrait {
      grid-auto-rows: 40vw;
      // grid-template-columns: 100%;
      grid-gap: 2.564vw;
      grid-template-columns: repeat(auto-fit, minmax(40vw, 1fr));
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.75;
  }

  &__grid-tall {
    @include landscape {
      grid-row: span 2;
    }
  }

  &__grid-big {
    @include landscape {
      grid-column: span 2;
      grid-row: span 2;
    }
  }

  &__grid-inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__modal {
    position: sticky;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
  }

  &__modal-close {
    position: fixed;
    box-sizing: content-box;
    right: 10vw;
    z-index: 1000;
    cursor: pointer;
    @include landscape {
      width: 1.5vw;
      height: 1.5vw;
      padding: calc(25px - 0.75vw);
      top: 5vw;
    }
    @include portrait {
      padding: calc(25px - 2.5vw);
      width: 5vw;
      height: 5vw;
      right: -0.7vw;
      bottom: 10svh;
    }

    & div {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
    }

    & div > span {
      position: absolute;
      top: 50%;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 1px;
      background-color: #fff;
    }

    & div > span:nth-child(1) {
      rotate: 45deg;
    }

    & div > span:nth-child(2) {
      rotate: -45deg;
    }
  }

  &__swiper-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }

  &__swiper-img {
    @include landscape {
      width: auto;
      height: 100%;
    }
    @include portrait {
      width: 100%;
      height: auto;
    }
  }
}

.inview {
  animation-name: inview;
  animation-duration: 2s;
}

@keyframes inview {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.pc-grid-tall {
  @include landscape {
    grid-row: span 2;
  }
}

.pc-grid-big {
  @include landscape {
    grid-column: span 2;
    grid-row: span 2;
  }
}

.sp-grid-tall {
  @include portrait {
    grid-row: span 2;
  }
}

.sp-grid-big {
  @include portrait {
    grid-column: span 2;
    grid-row: span 2;
  }
}

.trumpsLandscape {
  @include portrait {
    display: none;
  }
}

.trumpsPortrait {
  @include landscape {
    display: none;
  }
}

@import '../../mixin';
@import '../../trumps';
@import '../../settings';

.background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -100;
  &__vid-wrap {
    width: 100%;
    height: 101%;
  }

  &__video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    opacity: 0.8;
    mix-blend-mode: soft-light;
    z-index: -100;
  }

  &__c {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #2a312e;
    z-index: -150;
  }
}

.white-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  background-image: url(../../../public/bg-02.jpg);
  background-size: cover;
  background-position: center;
}

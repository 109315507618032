@import '../../mixin';

.thanks {
  &__container {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 80vw;
    height: 100svh;
  }
}

@import '../../mixin';

.cursor {
  @include landscape {
    position: absolute;
    justify-content: center;
    align-items: center;
    width: max-content;
    transition: all 0.2s ease-out;
    z-index: 10;
    border-radius: 50%;
  }
  @include portrait {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  &__text {
    @include portrait {
      display: none;
    }
    @include landscape {
      font-size: 0.8vw;
    }
  }
}

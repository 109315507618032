@import '../../mixin';
@import '../../settings';
@import '../../trumps';

.transition {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100svh;
  z-index: 1000;
  background-color: $bpc-black;
}

.transition-flex {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.transition-image {
  width: 50vw;
  @include tablet {
    width: 40vw;
  }
  @include pc {
    width: 35vw;
  }
}

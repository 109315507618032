@import '../../mixin';
@import '../../trumps';
@import '../../settings';

.weather {
  position: fixed;
  top: 0;
  right: 5vw;
  height: 18.7vw;
  z-index: 10;
  transition: all 0.5s ease;
  @include tablet {
    height: 18.3vw;
  }
  @include pc {
    height: 9.13vw;
  }

  &__container {
    display: flex;
    height: 100%;
    width: 15vw;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    margin: 0 auto;
    @include tablet {
      width: 13vw;
      align-items: center;
      flex-direction: row;
    }
  }

  &__inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include tablet {
      margin: 0 1vw;
    }
    @include pc {
      margin: 0 0.764vw;
    }

    &:nth-last-child(1) {
      margin-right: 0;
      @include tablet {
        margin-left: 1vw;
      }
      @include pc {
        margin-left: 0.764vw;
      }
    }
  }

  &__text {
    text-transform: capitalize;
    font-size: 10px;
    letter-spacing: 0.04em;
    line-height: 1;
    @include tablet {
      font-size: 1.9vw;
      margin-left: 0.3vw;
    }
    @include pc {
      font-size: 0.95vw;
      margin-left: 0.3vw;
    }
  }

  &__temp {
    display: inline-block;
    @include tablet {
    }
    @include pc {
      margin-left: 0.4vw;
    }
  }

  &__mark {
    font-size: 10px;
    @include tablet {
      font-size: 1.6vw;
    }
    @include pc {
      font-size: 0.87vw;
    }
  }

  &__icon {
    width: 7vw;
    @include tablet {
      width: 4.5vw;
    }
    @include pc {
      width: 2.4vw !important;
      max-width: 2.4vw !important;
      margin: 0.15vw 0 0;
    }
  }

  &__pool {
    width: 4.7vw;
    margin-left: 0.8vw;
    @include tablet {
      margin-right: 0.5vw;
      width: 3.1vw;
    }
    @include pc {
      margin-right: 0.3vw;
      width: 1.4vw;
    }
  }
}

.weather-black {
  color: $bpc-black;
}

.weather-white {
  color: #fff;
}

.weather-icon-black {
  path {
    fill: $bpc-black;
  }
}

.weather-icon-white {
  path {
    fill: #fff;
  }
}

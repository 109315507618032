@import '../../mixin';
@import '../../settings';
@import '../../trumps';

.newsletter {
  &__container {
    width: 84vw;
    margin: 0 auto;
    padding: 15vw 3.5vw 0;
    border: 1px solid $bpc-white;
    @include tablet {
      padding: 10vw 6vw 1.5vw;
    }
    @include pc {
      padding: 7vw 4.5vw 1.5vw;
      width: 46.3vw;
    }
  }

  &__logo {
    display: block;
    margin: 0 auto;
    width: 22vw;
    margin-bottom: 12vw;
    @include tablet {
      width: 13vw;
      margin-bottom: 7.5vw;
    }
    @include pc {
      width: 8vw;
      margin-bottom: 4.5vw;
    }
  }

  &__text {
    font-family: 'Cormorant Garamond', 'Zen Old Mincho', serif;
    letter-spacing: 0.1em;
    color: $bpc-white;
    text-align: justify;
    width: 100%;
    margin-bottom: 7vw;
    @include tablet {
      font-size: 1.86vw;
      margin-bottom: 5vw;
    }
    @include pc {
      font-size: 0.98vw;
      margin-bottom: 3vw;
    }
  }

  &__iframe {
    width: 100%;
    height: 65.6vw;
    @include tablet {
      height: 37vw;
    }
    @include pc {
      height: 19vw;
    }
  }
}

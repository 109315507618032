@import '../../mixin';
@import '../../trumps';
@import '../../settings';

.mv {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
  height: 100svh;
  @include landscape {
    margin-bottom: 10vw;
  }
  @include portrait {
    margin-bottom: 30vw;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
  }

  &__swiper {
    width: 100%;
  }

  &__scontent {
    width: 100%;
    z-index: -10;
  }

  &__item {
    width: 100%;
    z-index: -10;
  }

  &__cover {
    opacity: 1;
    position: relative;
    z-index: -10;
    width: 100%;
    object-fit: cover;
    object-position: bottom;
    height: 100svh;
    @include pc {
      height: 100vh;
    }
  }

  &__bed {
    @include portrait {
      object-position: left bottom !important;
    }
  }

  &__pagination-wrap {
    position: absolute;
    width: 100vw;
    @include landscape {
      bottom: 3vw;
    }
    @include portrait {
      bottom: 8svh;
    }
  }

  &__pagination {
    bottom: 0;
    width: 82vw;
    margin: 0 auto;
    @include pc {
      width: 84vw;
      margin: 0 auto;
    }
  }

  &__bar {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 1vw;
    @include pc {
      margin: 0 2vw;
    }
  }

  &__index {
    display: none;
    @include pc {
      display: flex;
      justify-content: flex-start;
    }

    &--text {
      display: inline-block;
      margin-left: 0.3vw;
      font-size: 0.95vw;
      padding-bottom: 0.2vw;
    }
  }

  &__title {
    padding-top: 0.2vw;
    padding-bottom: 0;
  }

  .swiper-pagination {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative !important;
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    height: fit-content;
    border-radius: 0;
    background: none;
    width: 100%;
    opacity: 1 !important;
    margin: 0 !important;

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $bpc-white;
      opacity: 0.4;
      z-index: 1;
    }

    .progressing {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $bpc-white;
      width: 0%;
      height: 1px;
      z-index: 10;
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;
    background: none !important;
    .progressing {
      animation-name: countingBar;
      animation-duration: 4s;
      animation-timing-function: ease-in;
      animation-iteration-count: 1;
      animation-direction: alternate;
      animation-fill-mode: forwards;
    }
    @keyframes countingBar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
        opacity: 1;
      }
    }
  }

  .animationFadeOut {
    animation-duration: 1s;
    animation-name: animation-fadeout;
    opacity: 0;
  }

  .animationFadeIn {
    animation-duration: 1s;
    animation-name: animation-fadeout;
    opacity: 1;
  }

  @keyframes animation-fadeout {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes animation-fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 100%;
    }
  }

  &__flex {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 10;
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Noto Serif JP', serif;
  }

  &__place {
    cursor: pointer;
    font-family: 'Jost', sans-serif;
    margin-bottom: 12vw;
    opacity: 0;
    transition: all 1s;
    margin-top: 6.5vw;
    @include tablet {
      margin-top: 4vw;
      margin-bottom: 8vw;
    }
    @include pc {
      margin-top: 2vw;
      margin-bottom: 2.5vw;
    }
    &--text {
      letter-spacing: 0;
      display: inline-block;
      font-size: 5.128vw;
      font-weight: 600;
      margin-left: 3.077vw;
      margin-right: 1.282vw;
      @include pc {
        margin-right: 0.694vw;
        font-size: 2.431vw;
      }
    }
    &--icon {
      display: inline-block;
      width: 3.077vw;
      padding-bottom: 5.128vw;
      @include tablet {
        width: 2.99vw;
        padding-bottom: 4.785vw;
      }
      @include pc {
        width: 1.736vw;
        padding-bottom: 3.125vw;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: row;
    opacity: 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 5.128vw;
    z-index: 10;
    @include tablet {
      margin: 0 4.268vw;
    }
    @include pc {
      margin: 0 3.125vw;
    }
  }

  &__last {
    margin-bottom: 0;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include pc {
      margin-bottom: 1.25vw;
    }
  }

  &__i {
    margin-right: 3.333vw;
    width: 5.128vw;
    @include tablet {
      margin-right: 2.153vw;
      width: 3.828vw;
    }
    @include pc {
      margin-right: 1.25vw;
      width: 2.222vw;
    }
  }

  &__value {
    color: #fff;
    letter-spacing: 0.3em;
    font-size: 3.846vw;
    @include tablet {
      font-size: 2.751vw;
    }
    @include pc {
      font-size: 1.4vw;
    }
  }

  &__slash {
    font-size: 3.077vw;
    margin: 0 1.282vw;
    @include tablet {
      font-size: 2.273vw;
      margin: 0 0.598vw;
    }
    @include pc {
      font-size: 1.319vw;
      margin: 0 0.347vw;
    }
  }

  &__unit {
    margin-left: 0.5vw;
    font-size: 3.077vw;
    @include tablet {
      margin-left: 0.598vw;
      font-size: 2.273vw;
    }
    @include pc {
      margin-left: 0.347vw;
      font-size: 1.1vw;
    }
  }

  &__description {
    color: #fff;
    display: block;
    font-size: 10px;
    font-family: 'Cormorant+Garamond', serif;
    @include pc {
      font-size: 0.95vw;
    }
  }

  &__scroll {
    margin-bottom: 9.7vw;
    @include pc {
      margin-bottom: 4vw;
    }
    &--text {
      font-size: 10px;
      margin-bottom: 0.5vw;
      letter-spacing: 0.08em;
    }
    &--icon {
      border: 1px solid #fff;
      width: 4vw;
      height: 4vw;
      margin: 1vw auto 0;
      @include tablet {
        width: 2vw;
        height: 2vw;
        margin: 0 auto;
      }
      @include pc {
        width: 1.5vw;
        height: 1.5vw;
        max-width: 20px;
        max-height: 20px;
        margin: 0 auto;
      }
    }
  }

  &__side {
    display: none;
    @include pc {
      display: block;
      width: 5vw;
    }
  }

  &__sns {
    display: none;
    @include pc {
      width: 1.667vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }

  &__icon {
    width: 1.667vw;
  }

  &__bottom {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    opacity: 0;
    z-index: 10;
    @include landscape {
      bottom: 12.5vw;
    }
    @include portrait {
      bottom: 38vw;
    }
  }

  &__text {
    color: #fff;
    font-size: 3.59vw;
    @include tablet {
      font-size: 2.392vw;
    }
    @include pc {
      font-size: 1.389vw;
    }
  }

  @keyframes show-animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes hidden-animation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.mv-show {
  animation: show-animation;
  animation-duration: 1s;
  opacity: 1;
}

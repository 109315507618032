@import '../../mixin';
@import '../../trumps';

.contact {
  width: 100vw;
  margin: 26.7vw auto 0;
    @include tablet {
        margin: 20.9vw auto 0;
    }
    @include pc {
        margin: 15.63vw auto 0;
    }

  &__container {
    width: 79vw;
    margin: 0 auto;
    display: flex;
    @include pc {
      width: 90%;
    }
  }

  &__side {
    width: 100%;
    display: none;
    @include pc {
      display: block;
      width: 5vw;
    }
  }

  &__content {
    width: 100%;
  }

  &__title {
    margin-bottom: 10.7vw;
    @include tablet {
      margin-bottom: 7.9vw;
    }
    @include pc {
      margin-bottom: 6.52vw;
    }
  }

  &__text {
    margin-bottom: 10vw;
    @include tablet {
      margin-bottom: 5vw;
    }
    @include pc {
      margin-bottom: 3vw;
    }
  }

  iframe {
    width: 100%;
    height: 220vw;
    @include tablet {
      height: 115vw;
    }
    @include pc {
      height: 62vw;
    }
  }
}
@import '../../mixin';
@import '../../trumps';
@import '../../settings';

.gallery {
  width: 100vw;
  // margin-top: 30vw;
  display: flex;
  justify-content: flex-end;
  @include tablet {
    // margin-top: 25vw;
  }
  @include pc {
    // margin-top: 20vw;
  }

  &__container {
    width: 90%;
    margin: 0;
    @include pc {
      width: fit-content;
      margin: 0 auto;
    }
  }

  &__side {
    width: 100%;
    @include pc {
      width: 10vw;
    }
  }

  &__side-text {
    display: none;
    @include pc {
      display: inline-block;
    }
  }

  &__title {
    @include pc {
      width: 80vw;
      margin: 0 auto;
    }
  }

  &__text {
    font-size: 8vw;
    letter-spacing: $default-letter-spacing;
    margin-bottom: 5vw;
    @include tablet {
      font-size: 5.1vw;
      margin-bottom: 4vw;
    }
    @include pc {
      font-size: 3.2vw;
      margin-bottom: 3.5vw;
    }
  }

  &__contents {
    width: 100%;
    @include pc {
      width: 85vw;
    }
  }

  &__swiper {
    width: 90vw;
    @include pc {
      margin: 0 auto;
      width: 100%;
    }
  }

  &__content {
    box-sizing: border-box;
    margin-right: 10vw;
    @include tablet {
      margin-right: 5vw;
    }
    @include pc {
      margin: 0 2.5vw;
    }
  }

  &__item {
    width: 100%;
    aspect-ratio: 1/1;
  }

  &__img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    width: 80vw;
    @include pc {
      margin: 0 auto;
    }
  }

  &__control {
    margin-top: 2.5vw;
  }

  &__btn-wrap {
    width: 80vw;
    @include pc {
      width: 33.333333%;
    }
  }

  &__btn {
    margin-top: 4vw;
    @include pc {
      margin-bottom: 0.6vw;
    }
  }

  &__underline {
    width: fit-content;
    border-bottom: 1px solid $bpc-white;
  }

  &__blocks {
    width: 2.5vw;
    @include pc {
      margin-bottom: 0.3vw;
      width: 1vw;
    }
  }

  &__slash {
    margin: 0 0.1vw;
    font-size: 1.3vw;
  }

  .swiper-pagination-custom {
    width: fit-content;
    margin-bottom: -0.3vw;
  }
}

.gallery-button {
  display: inline-block;
  position: relative !important;
  margin: 0 3.5vw;
  padding: 1vw;
  top: 0;
  left: 0;
}

.gallery-button::after {
  font-size: 0.5vw;
  color: white !important;
}

@import '../../mixin';
@import '../../trumps';
@import '../../settings';

.menu {
  position: fixed;
  width: 100vw;
  height: 18.7vw;
  top: 0;
  z-index: 10;
  transition: all 0.5s ease-in-out;
  @include tablet {
    height: 18.3vw;
  }
  @include pc {
    height: 9.13vw;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90vw;
    height: 100%;
    margin: 0 auto;
  }

  &__logo {
    width: 55vw;
    max-width: 600px;
    transition: all 0.5s ease-in-out;
    @include tablet {
      width: 40vw;
    }
    @include pc {
      width: 25vw;
    }
  }

  &__gallery {
    opacity: 0;
    display: flex;
    transition: all 0.3s ease;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    @include tablet {
      padding: 0 2px;
    }

    &--text {
      display: none;
      @include tablet {
        display: inline-block;
        color: $bpc-white;
        font-family: 'Quicksand', sans-serif;
        font-size: 1.435vw;
        vertical-align: text-bottom;
      }
      @include pc {
        font-size: 0.972vw;
      }
    }
  }

  &__lightbox {
    width: 5vw;
    @include tablet {
      width: 1.8vw;
      margin-left: 0.718vw;
    }
    @include pc {
      width: 0.972vw;
      margin-left: 0.556vw;
    }
  }
}

.menu-black {
  background: linear-gradient(rgba(11, 18, 20, 0.4), rgba(11, 18, 20, 0));
}

.menu-logo-white {
  path {
    fill: $bpc-white;
  }
}

.menu-white {
  background: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.menu-logo-black {
  path {
    fill: $bpc-black;
  }
}

.menu-scroll-down {
  @include pc {
    height: 5vw !important;
  }

  .menu__logo {
    @include pc {
      width: 20vw;
    }
  }
}

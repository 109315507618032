@tailwind base;
@tailwind components;
@tailwind utilities;

@import './mixin';
@import './settings';

html {
  &::-webkit-scrollbar {
    display: none;
  }
}

body {
  overflow-x: hidden;
  letter-spacing: $default-letter-spacing;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: 'lnum' 1;
  -moz-font-feature-settings: 'lnum=1';
  -ms-font-feature-settings: 'lnum' 1;
  -o-font-feature-settings: 'lnum' 1;
  -webkit-font-feature-settings: 'lnum' 1;
  font-feature-settings: 'lnum' 1;
}

input,
textarea,
select {
  background: none;
  width: 100%;
  border: 1px solid $bpc-white;
}

input::placeholder,
textarea::placeholder {
  color: $bpc-white;
}

.text-white {
  color: $bpc-white;
}

.font-serif {
  font-family: 'Cormorant Garamond', 'Zen Old Mincho', serif;
}

.font-mincho {
  font-family: 'Zen Old Mincho', serif;
}

.font-infant {
  font-family: 'Cormorant Infant', serif;
}

.font-quicksand {
  font-family: 'Quicksand', sans-serif;
}

h1 {
  font-size: 8.5vw;
  @include tablet {
    font-size: 5.25vw;
  }
  @include pc {
    font-size: 3.3vw;
  }
}

h2 {
  font-size: calc(22px + $default-font-size);
  @include pc {
    font-size: calc(20px + $default-font-size);
  }
}

h3 {
  font-size: calc(8px + $default-font-size);
  @include pc {
    font-size: calc(15px + $default-font-size);
  }
}

h4 {
  font-size: 4vw;
  @include tablet {
    font-size: 2.7vw;
  }
  @include pc {
    font-size: 1.5vw;
  }
}

p {
  font-size: 3.05vw;
  @include tablet {
    font-size: 1.9vw;
  }
  @include pc {
    font-size: 0.95vw;
  }
}

.p-en {
  font-size: 3.35vw;
  @include tablet {
    font-size: 1.9vw;
  }
  @include pc {
    font-size: 1vw;
  }
}

.p1 {
  line-height: 1.88;
}

.p2 {
  line-height: 2.1;
}

.section-title {
  font-size: 10px;
  @include pc {
    font-size: calc($default-font-size - 1px);
  }
  @include tablet {
    font-size: $default-font-size;
  }
}

.all-scenes-text {
  font-size: 12px;
  @include tablet {
    font-size: $default-font-size;
  }
}

.sc-title {
  letter-spacing: $default-letter-spacing;
  font-size: 6vw;
  @include tablet {
    font-size: 3.6vw;
  }
  @include pc {
    font-size: 2.22vw;
  }
}

.caption {
  font-size: 4.5vw;
  @include tablet {
    font-size: 2.8vw;
  }
  @include pc {
    font-size: 1.32vw;
  }
}

.lightbox-text {
  font-size: 13px;
  @include pc {
    font-size: calc($default-font-size - 1px);
  }
}

.small-text {
  font-size: 12px;
}

.check-text {
  font-size: calc(4px + $default-font-size);
  @include pc {
    font-size: calc($default-font-size - 2px);
  }
  @include tablet {
    font-size: calc($default-font-size - 3px);
  }
}

.swiper-num-text1 {
  font-family: 'Cormorant Infant', serif;
  @include pc {
    font-size: 2.3vw;
  }
}

.swiper-num-text2 {
  font-family: 'Cormorant Infant', serif;
  @include pc {
    font-size: 1.5vw;
  }
}

.concept-subtitle {
  letter-spacing: $default-letter-spacing;
  font-size: 3.8vw;
  @include tablet {
    font-size: 2.35vw;
  }
  @include pc {
    font-size: 1.5vw;
  }
}

.vertical-rl {
  @include pc {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
  }
}

.cw-90 {
  width: 90vw;
}

.cw-80 {
  @include pc {
    width: 80vw;
  }
}

.cw-79 {
  width: 79vw;
}

.cw-50 {
  width: 100%;
  @include pc {
    width: calc(100% - 2vw);
  }
}

.leading-more-loose {
  line-height: 2.5;
}

.tracking-loose {
  letter-spacing: 0.075em;
}

.text-area {
  @include pc {
    width: calc(100% - 2vw);
  }
}

@import '../../mixin';
@import '../../trumps';

.sc {
  width: 100vw;
  margin-bottom: 15vw;
  display: flex;
  justify-content: flex-end;

  &__container {
    width: 90%;
    margin-top: 20vw;
    @include pc {
      display: flex;
      justify-content: center;
      width: 100%;
      margin: 13vw auto 0;
    }
  }

  &__contents {
    width: 100%;
    @include pc {
      width: 85vw;
    }
  }

  &__title {
    margin-bottom: 4vw;
    @include tablet {
      margin-bottom: 2.7vw;
    }
    @include pc {
      margin-bottom: 2vw;
      margin-left: 2.5vw;
      text-align: left;
    }
  }

  &__swiper {
    width: 90vw;
    @include pc {
      margin: 0 auto;
      width: 100%;
    }
  }

  &__content {
    box-sizing: border-box;
    margin-right: 10vw;
    @include tablet {
      margin-right: 5vw;
    }
    @include pc {
      margin: 0 2.5vw;
    }
  }

  &__item {
    position: relative;
  }

  &__overlay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center;
  }

  &__facilities {
    text-align: center;
    line-height: 1;
    @include landscape {
      margin: 1.8vw 0 1.2vw;
      font-size: 2vw;
    }
    @include portrait {
      // margin: 1.8vw 0 1.2vw;
      margin: 7vw 0 6vw;
      font-size: min(6vw, 28px);
    }
  }

  &__text {
    letter-spacing: 0em;
    line-height: 2;
    @include landscape {
      font-size: 0.93vw;
    }
    @include portrait {
      font-size: min(2.85vw, 16px);
    }
  }
}

.gallary-button {
  display: inline-block;
  position: relative !important;
  margin: 0 15px;
  padding: 1vw;
  top: 0;
  left: 0;
}

.gallary-button::after {
  font-size: 1vmin;
  color: white !important;
}

@import '../../mixin';
@import '../../trumps';
@import '../../settings';

.mediakit {
  width: 80%;
  margin: 26.7vw auto 0;
  @include tablet {
    margin: 20.9vw auto 0;
  }
  @include pc {
    margin: 15.63vw auto 0;
  }

  &__title {
    color: $bpc-black;
    margin-bottom: 10.7vw;
    @include tablet {
      margin-bottom: 7.9vw;
    }
    @include pc {
      margin-bottom: 6.55vw;
    }
  }

  &__message {
    color: $bpc-black;
    margin-bottom: 10.7vw;
    @include tablet {
      margin-bottom: 7.9vw;
    }
    @include pc {
      margin-bottom: 6.55vw;
    }
  }

  &__subtitle {
    margin-bottom: 5.5vw;
    @include tablet {
      margin-bottom: 4vw;
    }
    @include pc {
      margin-bottom: 2.65vw;
    }
  }

  &__text {
    color: $bpc-black;
    margin-bottom: 5.4vw;
    @include tablet {
      margin-bottom: 4vw;
    }
    @include pc {
      margin-bottom: 2.65vw;
    }
  }

  &__box {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10.7vw;
    @include tablet {
      margin-bottom: 7.9vw;
    }
    @include pc {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 6.55vw;
    }
  }

  &__inner {
    width: 100%;
    margin-bottom: 5.4vw;
    @include tablet {
      margin-bottom: 4vw;
    }
    @include pc {
      margin-bottom: 0;
      justify-content: space-between;
      width: 38vw;
    }
  }

  &__placeholder {
    width: 100%;
    aspect-ratio: 8/5;
    background-position: center center;
    background-size: cover;
  }

  &__bgl {
    background-image: url(../../../public/MediaKit_Thumbnail_BrandGuidelines.jpg);
  }

  &__lp {
    background-image: url(../../../public/MediaKit_Thumbnail_LogoPack.jpg);
  }

  &__va {
    background-image: url(../../../public/MediaKit_Thumbnail_BrandVisualAssets.jpg);
  }

  &__button {
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    border: 1px solid $bpc-black;
    color: $bpc-black;
    transition: all 0.3s;
    padding: 3vw 6.2vw;
    font-size: 3.3vw;
    @include tablet {
      padding: 2.1vw 4.1vw;
      font-size: 2vw;
    }
    @include pc {
      padding: 1.3vw 2.8vw;
      font-size: 1vw;
    }

    &:hover {
      background-color: $bpc-black;
      color: $bpc-white;

      .cls-1 {
        fill: $bpc-white;
      }
    }
  }

  &__df {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__icon {
    .cls-1 {
      fill: $bpc-black;
      transition: all 0.3s;
    }
    margin-right: 2vw;
    width: 3vw;
    @include tablet {
      margin-right: 1vw;
      width: 2vw;
    }
    @include pc {
      margin-right: 0.5vw;
      width: 0.8vw;
    }
  }
}

@import './mixin';

.pc-only {
  display: none;
  @include pc {
    display: block;
  }
}

.tablet-only {
  display: block;
  @include pc {
    display: none;
  }
}

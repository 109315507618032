@import '../../mixin';
@import '../../settings';
@import '../../trumps';

.pool {
  width: 100vw;

  &__title {
    letter-spacing: $default-letter-spacing;
    // margin-top: 30vw;
    margin-top: 0;
    margin-bottom: 7vw;
    @include tablet {
      // margin-top: 20vw;
      margin-top: 0;
      margin-bottom: 6vw;
    }
    @include pc {
      margin-top: 11vw;
      margin-bottom: 3.2vw;
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    margin: 30% 10%;
    z-index: 20;
    @include tablet {
      margin: 20% 10%;
    }
    @include pc {
      margin: 0 10%;
    }
  }

  &__text {
    line-height: 2;
    letter-spacing: 0.04em;
    text-shadow: $bpc-black 0 0 20px;
    @include pc {
      letter-spacing: $default-letter-spacing;
      line-height: 2.5;
      text-shadow: $bpc-black 0.021vw 0 0;
    }
  }

  &__swiper {
    width: 100%;
  }

  &__content {
    width: 100%;
  }

  &__item {
    width: 100%;
  }

  &__cover {
    opacity: 1;
    width: 100%;
    object-fit: cover;
    object-position: top;
    // -webkit-mask-image: linear-gradient(to top, black 0%, transparent 100%);
    height: 100svh;
    @include pc {
      height: 130vh;
    }
  }

  &__pagination {
    position: relative;
    width: 82vw;
    margin: 4vw auto 0;
    @include pc {
      width: 84vw;
      margin: 3vw auto 0;
    }
  }

  &__bar {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 1vw;
    @include pc {
      margin: 0 2vw;
    }
  }

  &__index {
    display: none;
    @include pc {
      display: flex;
      justify-content: flex-start;
    }

    &--text {
      display: inline-block;
      margin-left: 0.3vw;
    }
  }

  .swiper-pagination {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative !important;
    bottom: 0 !important;
  }

  .swiper-pagination-bullet {
    height: fit-content;
    border-radius: 0;
    background: none;
    width: 100%;
    opacity: 1 !important;
    margin: 0 !important;

    .progress {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $bpc-white;
      opacity: 0.4;
      z-index: 1;
    }

    .progressing {
      position: absolute;
      top: 0;
      left: 0;
      background-color: $bpc-white;
      width: 0%;
      height: 1px;
      z-index: 10;
    }
  }

  .swiper-pagination-bullet-active {
    opacity: 1 !important;
    background: none !important;
    .progressing {
      animation-name: countingBar;
      animation-duration: 4s;
      animation-timing-function: ease-in;
      animation-iteration-count: 1;
      animation-direction: alternate;
      animation-fill-mode: forwards;
    }
    @keyframes countingBar {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }

  &__bottom {
    width: 80vw;
    display: flex;
    justify-content: flex-end;
    margin: 5vw auto 0;
    @include tablet {
      margin: 3.5vw auto 0;
    }
    @include pc {
      width: 80vw;
      margin: 3vw auto 0;
    }

    &--btn {
      width: fit-content;
      border-bottom: 1px solid $bpc-white;
    }

    &--blocks {
      display: inline-block;
      width: 14px;
      margin: 0 2px 2px;
      @include pc {
        margin-bottom: 0.3vw;
        width: 1vw;
      }
    }
  }
}

.animationFadeOut {
  animation-duration: 1s;
  animation-name: animation-fadeout;
  opacity: 0;
}

.animationFadeIn {
  animation-duration: 1s;
  animation-name: animation-fadeout;
  opacity: 1;
}

@keyframes animation-fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes animation-fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}
